body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.MuiTypography-button {
    font-size: 1.15em !important;
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: 900 !important;
}

.MuiTypography-h6 {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: bold !important;
}

.MuiTypography-caption {
    font-size: 1.1em !important;
    font-family: 'Noto Sans JP', sans-serif !important;
}

.appbar {
    align-items: center;
    box-shadow: none !important;
    border-bottom-style: solid;
    border-width: 3px;
}

.footerbar {
    align-items: center;
    justify-content: center;
    top: auto !important;
    bottom: 0;
    height: 40px;
}

.container {
    padding-top: 64px;
    max-width: 900px;
    margin: 0 auto;
}

.border-light {
    border-color: #404040;
}

.border-dark {
    border-color: #e5e5e5;
}

.border-result-light {
    border-color: #525252 !important;
}

.border-result-dark {
    border-color: white !important;
}

#logo {
    max-width: 200px;
    margin: 0 0.5em;
}

#discordLogo {
    max-width: 100px;
}

.logo-dark {
    filter: invert();
}

.sui-layout-body {
    background: none !important;
}

.sui-result-origin {
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.5;
    list-style: none;
    padding: 10px 0;
    text-decoration: none;
    display: block;
    border: solid;
    border-width: 2px;
    border-radius: 0px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-wrap: break-word;
    margin: 10px 0;
}

.sui-result__title-link-origin {
    text-decoration: none;
}

.sui-result__details-origin {
    list-style: none;
    padding: 4px 24px;
}

.sui-layout-sidebar {
    width: 0px;
    padding: 0 0;
}

.sui-layout-main {
    width: 100%;
    padding-top: 32px;
    padding-left: 0px;
}

.sui-layout-body:after {
    height: 25px !important;
    background: none !important;
}

.sui-layout-header {
    width: 100%;
}

.sui-select__option {
    color: black !important;
}

.link-light,
.light {
    color: #262626;
}

.link-dark,
.dark {
    color: #FAFAFA;
}

.link-light:hover,
.link-dark:hover {
    color: #A3A3A3;
}

.paging-footer {
    font-size: 1em !important;
    font-family: 'Noto Sans JP', sans-serif !important;
}

.paging-typo-light > .rc-pagination-item a {
    color: #a3a3a3;
}

.paging-typo-light > .rc-pagination-item:hover a,
.paging-typo-light > .rc-pagination-jump-next:hover:after,
.paging-typo-light > .rc-pagination-next:hover a {
    color: #262626;
}

.paging-typo-dark > .rc-pagination-item a {
    color: #737373;
}

.paging-typo-dark > .rc-pagination-item:hover a,
.paging-typo-dark > .rc-pagination-jump-next:hover:after,
.paging-typo-dark > .rc-pagination-next:hover a
{
    color: #262626 !important;
}

.rc-pagination-item-active:hover,
.rc-pagination-item-active a:hover
{
    cursor: unset !important;
}

.paging-typo-light > .rc-pagination-item-active a {
    color: #262626 !important;
}

.paging-typo-dark > .rc-pagination-item-active a {
    color: white !important;
}

.rc-pagination-disabled {
    cursor: pointer;
}

.sui-search-box__submit {
    background: #334155;
    border: 1px solid #262626;
    padding: 12px;
}

.sui-search-box__submit:hover {
    background: white;
    color: #262626;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #262626;
}

.sui-search-box__wrapper {
    align-items: center;
}